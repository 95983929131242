
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";

export default defineComponent({
  name: "Root",

  computed: {
    // layout() {
    //   const route = useRoute();
    //   if (route.meta.context == "auth") {
    //     return "empty-layout";
    //   }
    //   console.log(store.state.role);
    //   if (store.state.role == "system") {
    //     return "system-layout";
    //   } else if (store.state.role == "temple") {
    //     return "temple-layout";
    //   }
    //   return "empty-layout";
    // },
  },

  async mounted() {
    store.dispatch("refreshUser");
  },
});
