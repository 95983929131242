import { Auth } from "aws-amplify";
import axios from "axios";

interface MediaResourceJson {
  original_resource_url: string;
  thumbnail_url: string | null;
  resource_url: string | null;
}

class MediaResource {
  original_resource_url: string;
  thumbnail_url: string | null;
  resource_url: string | null;

  constructor(
    original_resource_url: string,
    thumbnail_url: string | null,
    resource_url: string | null
  ) {
    this.original_resource_url = original_resource_url;
    this.thumbnail_url = thumbnail_url;
    this.resource_url = resource_url;
  }

  static fromJson(json: MediaResourceJson): MediaResource {
    return new MediaResource(
      json.original_resource_url,
      json.thumbnail_url,
      json.resource_url
    );
  }
}

export { MediaResourceJson, MediaResource };
