import moment from "moment";

export const formatMessageDateTime = (datetime: Date | null) => {
  if (datetime == null) {
    return "";
  }

  const dt = moment(datetime);
  const now = moment();

  const diff = now.diff(dt);

  if (diff <= 60 * 1000) {
    return "今";
  }

  if (diff <= 60 * 60 * 1000) {
    const min = (diff / (60 * 1000)).toFixed(0);
    return `${min}分前`;
  }
  if (diff <= 24 * 60 * 60 * 1000) {
    const hour = (diff / (60 * 60 * 1000)).toFixed(0);
    return `${hour}時間前`;
  }
  if (diff <= 7 * 24 * 60 * 60 * 1000) {
    const day = (diff / (24 * 60 * 60 * 1000)).toFixed(0);
    return `${day}日前`;
  }

  if (diff <= 365 * 7 * 24 * 60 * 60 * 1000) {
    return dt.format("M月D日");
  }

  return dt.format("YYYY年M月D日");
};

export const zenkaku2hankaku = (text: string) => {
  const zen = [
      "ア","イ","ウ","エ","オ","カ","キ","ク","ケ","コ"
    ,"サ","シ","ス","セ","ソ","タ","チ","ツ","テ","ト"
    ,"ナ","ニ","ヌ","ネ","ノ","ハ","ヒ","フ","ヘ","ホ"
    ,"マ","ミ","ム","メ","モ","ヤ","ヰ","ユ","ヱ","ヨ"
    ,"ラ","リ","ル","レ","ロ","ワ","ヲ","ン"
    ,"ガ","ギ","グ","ゲ","ゴ","ザ","ジ","ズ","ゼ","ゾ"
    ,"ダ","ヂ","ヅ","デ","ド","バ","ビ","ブ","ベ","ボ"
    ,"パ","ピ","プ","ペ","ポ"
    ,"ァ","ィ","ゥ","ェ","ォ","ャ","ュ","ョ","ッ"
    ,"゛","°","、","。","「","」","ー","・"
  ];
  
  const han = [
    "ｱ","ｲ","ｳ","ｴ","ｵ","ｶ","ｷ","ｸ","ｹ","ｺ",
    "ｻ","ｼ","ｽ","ｾ","ｿ","ﾀ","ﾁ","ﾂ","ﾃ","ﾄ",
    "ﾅ","ﾆ","ﾇ","ﾈ","ﾉ","ﾊ","ﾋ","ﾌ","ﾍ","ﾎ",
    "ﾏ","ﾐ","ﾑ","ﾒ","ﾓ","ﾔ","ｲ","ﾕ","ｴ","ﾖ",
    "ﾗ","ﾘ","ﾙ","ﾚ","ﾛ","ﾜ","ｦ","ﾝ",
    "ｶﾞ","ｷﾞ","ｸﾞ","ｹﾞ","ｺﾞ","ｻﾞ","ｼﾞ","ｽﾞ","ｾﾞ","ｿﾞ",
    "ﾀﾞ","ﾁﾞ","ﾂﾞ","ﾃﾞ","ﾄﾞ","ﾊﾞ","ﾋﾞ","ﾌﾞ","ﾍﾞ","ﾎﾞ",
    "ﾊﾟ","ﾋﾟ","ﾌﾟ","ﾍﾟ","ﾎﾟ",
    "ｧ","ｨ","ｩ","ｪ","ｫ","ｬ","ｭ","ｮ","ｯ",
    "ﾞ","ﾟ","､","｡","｢","｣","ｰ","･"
  ];

  let hankaku = "";

  for (let i = 0; i < text.length; i++) {
    let maechar = text.charAt(i);
    const zenindex = zen.indexOf(maechar);
    if (zenindex >= 0) {
      maechar = han[zenindex];
    }
    hankaku += maechar;
  }

  return hankaku;
};
