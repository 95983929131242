import { Auth } from "aws-amplify";
import axios from "axios";

interface BankJson {
  code: string;
  name: string;
  fullWidthKana: string;
}

export default class Bank {
  code: string;
  name: string;
  kana: string;

  constructor(code: string, name: string, kana: string) {
    this.code = code;
    this.name = name;
    this.kana = kana;
  }

  static async search(keyword: string): Promise<Bank[]> {
    console.log(`Bank.search(${keyword})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        apikey: process.env.VUE_APP_BANKCODEJP_API_KEY!,
      },
      params: {
        filter: `(code==*${keyword}*,name==*${keyword}*,hiragana==*${keyword}*)`,
        limit: 10,
      },
    };

    const response = await axios.get(
      `https://apis.bankcode-jp.com/v1/banks`,
      config
    );

    const list: Bank[] = [];
    response.data.data.forEach((json: BankJson) => {
      list.push(new Bank(json.code, json.name, json.fullWidthKana));
    });

    return list;
  }
}
