import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";

interface TimelineJson {
  id: string;
  type: string;
  scope: string;
  publisher_id: string;
  publisher_name: string | null;
  publisher_username: string | null;
  publisher_selfie_image_url: string | null;
  target_id: string | null;
  target_name: string | null;
  target_username: string | null;
  available_from: string | null;
  available_to: string | null;
  title: string | null;
  body: string | null;
  media_urls: MediaResourceJson[];
  event_date: string | null;
  place: string | null;
  stamp_count: number;
  comment_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface TimelineSearchResult {
  timelines: Timeline[];
  last_evaluated_key: string | null;
}

export default class Timeline {
  id: string;
  type: string;
  scope: string;
  publisher_id: string;
  publisher_name: string | null;
  publisher_username: string | null;
  publisher_selfie_image_url: string | null;
  target_id: string | null;
  target_name: string | null;
  target_username: string | null;
  available_from: Date | null;
  available_to: Date | null;
  title: string | null;
  body: string | null;
  media_urls: MediaResource[];
  event_date: Date | null;
  place: string | null;
  stamp_count: number;
  comment_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    type: string,
    scope: string,
    publisher_id: string,
    publisher_name: string | null,
    publisher_username: string | null,
    publisher_selfie_image_url: string | null,
    target_id: string | null,
    target_name: string | null,
    target_username: string | null,
    available_from: Date | null,
    available_to: Date | null,
    title: string | null,
    body: string | null,
    media_urls: MediaResource[],
    event_date: Date | null,
    place: string | null,
    stamp_count: number,
    comment_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.type = type;
    this.scope = scope;
    this.publisher_id = publisher_id;
    this.publisher_name = publisher_name;
    this.publisher_username = publisher_username;
    this.publisher_selfie_image_url = publisher_selfie_image_url;
    this.target_id = target_id;
    this.target_name = target_name;
    this.target_username = target_username;
    this.available_from = available_from;
    this.available_to = available_to;
    this.title = title;
    this.body = body;
    this.media_urls = media_urls;
    this.event_date = event_date;
    this.place = place;
    this.stamp_count = stamp_count;
    this.comment_count = comment_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(temple_id: string, timestamp: number): Promise<Timeline> {
    console.log(`Timeline.get(${temple_id}, ${timestamp})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/timelines/${temple_id}_${timestamp}`,
      config
    );

    const json = response.data.data as TimelineJson;

    return new Timeline(
      json.id,
      json.type,
      json.scope,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      json.target_id,
      json.target_name,
      json.target_username,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.title,
      json.body,
      json.media_urls,
      json.event_date ? new Date(json.event_date) : null,
      json.place,
      json.stamp_count,
      json.comment_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
