
import { defineComponent } from "vue";
import SystemSidebar from "@/components/SystemSidebar.vue";

export default defineComponent({
  components: { SystemSidebar },

  props: {
    page: String,
    title: String,
  },

  computed: {
    is_loading(): boolean {
      return this.$store.state.is_loading;
    },
  },

  methods: {
    toggleSidebarOpen() {
      this.$store.dispatch("toggleSidebarOpen");
    },

    signOut() {
      this.$store.dispatch("signOut");
    },
  },
});
