
import { defineComponent } from "vue";

interface SidebarState {
  activeClass: string;
  inactiveClass: string;
}

export default defineComponent({
  data() {
    return {
      activeClass:
        "flex items-center px-2 py-3 text-sm font-bold border-l-4 group border-color-2 bg-[#F7F9F6] text-gray-900",
      inactiveClass:
        "flex items-center px-2 py-3 text-sm font-bold border-l-4 group",
    } as SidebarState;
  },

  computed: {
    is_sidebar_open() {
      console.log("is_sidebar_open");
      return this.$store.state.is_sidebar_open;
    },
  },

  methods: {
    toggleSidebarOpen() {
      console.log("a");
      this.$store.dispatch("toggleSidebarOpen");
    },
  },
});
