import { Auth } from "aws-amplify";
import axios from "axios";

interface BillingJson {
  temple_id: string;
  temple_name: string;
  ym: number;
  order_count: number;
  manual_order_count: number;
  sales_by_cash: number;
  sales_by_card: number;
  fee: number | null;
  bank_code: string;
  bank_name: string;
  bank_name_kana: string;
  branch_code: string;
  branch_name: string;
  branch_name_kana: string;
  account_type: string;
  account_no: string;
  account_name: string;
  status: string;
  created_at: string;
}

interface BillingSearchResult {
  billings: Billing[];
  last_evaluated_key: string | null;
}

export default class Billing {
  temple_id: string;
  temple_name: string;
  ym: number;
  order_count: number;
  manual_order_count: number;
  sales_by_cash: number;
  sales_by_card: number;
  fee: number | null;
  bank_code: string;
  bank_name: string;
  bank_name_kana: string;
  branch_code: string;
  branch_name: string;
  branch_name_kana: string;
  account_type: string;
  account_no: string;
  account_name: string;
  status: string;
  created_at: Date;

  fee_error: string | null;

  get account_type_number(): string {
    if(this.account_type == "normal") {
      return "1";
    } else if(this.account_type == "check") {
      return "2";
    } else if(this.account_type == "deposit") {
      return "4";
    }
    return "";
  }

  get inout_total(): number {
    return this.sales_by_card - this.sales_by_cash - (this.fee ?? 0);
  }

  get status_label_for_system(): string {
    if (this.status == "waiting") {
      return this.inout_total > 0 ? "未送金" : "未入金";
    }
    return this.inout_total > 0 ? "送金済" : "入金済";
  }

  get status_label_for_temple(): string {
    if (this.status == "waiting") {
      return this.inout_total > 0 ? "未入金" : "未送金";
    }
    return this.inout_total > 0 ? "送金済" : "支払済";
  }

  constructor(
    temple_id: string,
    temple_name: string,
    ym: number,
    order_count: number,
    manual_order_count: number,
    sales_by_cash: number,
    sales_by_card: number,
    fee: number | null,
    bank_code: string,
    bank_name: string,
    bank_name_kana: string,
    branch_code: string,
    branch_name: string,
    branch_name_kana: string,
    account_type: string,
    account_no: string,
    account_name: string,
    status: string,
    created_at: Date
  ) {
    this.temple_id = temple_id;
    this.temple_name = temple_name;
    this.ym = ym;
    this.order_count = order_count;
    this.manual_order_count = manual_order_count;
    this.sales_by_cash = sales_by_cash;
    this.sales_by_card = sales_by_card;
    this.fee = fee;
    this.bank_code = bank_code;
    this.bank_name = bank_name;
    this.bank_name_kana = bank_name_kana;
    this.branch_code = branch_code;
    this.branch_name = branch_name;
    this.branch_name_kana = branch_name_kana;
    this.account_type = account_type;
    this.account_no = account_no;
    this.account_name = account_name;
    this.status = status;
    this.created_at = created_at;
    this.fee_error = "";
  }

  static async get(id: string): Promise<Billing> {
    console.log(`Billing.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/temples/${id}`,
      config
    );

    const json = response.data.data as BillingJson;

    return new Billing(
      json.temple_id,
      json.temple_name,
      json.ym,
      json.order_count,
      json.manual_order_count,
      json.sales_by_cash,
      json.sales_by_card,
      json.fee,
      json.bank_code,
      json.bank_name,
      json.bank_name_kana,
      json.branch_code,
      json.branch_name,
      json.branch_name_kana,
      json.account_type,
      json.account_no,
      json.account_name,
      json.status,
      new Date(json.created_at)
    );
  }

  static async search_by_ym(
    ym: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<BillingSearchResult> {
    console.log(`Billing.search_by_ym(${ym})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        ym: ym,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/billings`,
      config
    );

    const list: Billing[] = [];
    response.data.data.forEach((json: BillingJson) => {
      list.push(
        new Billing(
          json.temple_id,
          json.temple_name,
          json.ym,
          json.order_count,
          json.manual_order_count,
          json.sales_by_cash,
          json.sales_by_card,
          json.fee,
          json.bank_code,
          json.bank_name,
          json.bank_name_kana,
          json.branch_code,
          json.branch_name,
          json.branch_name_kana,
          json.account_type,
          json.account_no,
          json.account_name,
          json.status,
          new Date(json.created_at)
        )
      );
    });

    return {
      billings: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async search_by_temple_id(
    temple_id: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<BillingSearchResult> {
    console.log(
      `Billing.search(${temple_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        temple_id: temple_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/billings`,
      config
    );

    const list: Billing[] = [];
    response.data.data.forEach((json: BillingJson) => {
      list.push(
        new Billing(
          json.temple_id,
          json.temple_name,
          json.ym,
          json.order_count,
          json.manual_order_count,
          json.sales_by_cash,
          json.sales_by_card,
          json.fee,
          json.bank_code,
          json.bank_name,
          json.bank_name_kana,
          json.branch_code,
          json.branch_name,
          json.branch_name_kana,
          json.account_type,
          json.account_no,
          json.account_name,
          json.status,
          new Date(json.created_at)
        )
      );
    });

    return {
      billings: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async updateFee(
    temple_id: string,
    ym: number,
    fee: number
  ): Promise<Billing> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/billings/${temple_id}@${ym}`,
      {
        temple_id: temple_id,
        ym: ym,
        type: 'fee',
        fee: fee,
      },
      config
    );

    console.log(response);

    const json = response.data as BillingJson;

    return new Billing(
      json.temple_id,
      json.temple_name,
      json.ym,
      json.order_count,
      json.manual_order_count,
      json.sales_by_cash,
      json.sales_by_card,
      json.fee,
      json.bank_code,
      json.bank_name,
      json.bank_name_kana,
      json.branch_code,
      json.branch_name,
      json.branch_name_kana,
      json.account_type,
      json.account_no,
      json.account_name,
      json.status,
      new Date(json.created_at)
    );
  }

  static async updateStatus(
    temple_id: string,
    ym: number,
    status: string
  ): Promise<Billing> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/billings/${temple_id}@${ym}`,
      {
        temple_id: temple_id,
        ym: ym,
        type: 'status',
        status: status,
      },
      config
    );

    console.log(response);

    const json = response.data as BillingJson;

    return new Billing(
      json.temple_id,
      json.temple_name,
      json.ym,
      json.order_count,
      json.manual_order_count,
      json.sales_by_cash,
      json.sales_by_card,
      json.fee,
      json.bank_code,
      json.bank_name,
      json.bank_name_kana,
      json.branch_code,
      json.branch_name,
      json.branch_name_kana,
      json.account_type,
      json.account_no,
      json.account_name,
      json.status,
      new Date(json.created_at)
    );
  }
}
