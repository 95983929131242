
import { defineComponent } from "vue";
import moment from "moment";
import TempleLayout from "@/components/TempleLayout.vue";
import Menu from "@/models/Menu";

interface MenuItem {
  menu: Menu;
  is_enable: boolean;
  price: number | null;
}

interface MenuListState {
  is_loading: boolean;
  menus: MenuItem[];
  last_evaluated_key: string | null;
}

export default defineComponent({
  components: { TempleLayout },

  data() {
    return {
      is_loading: false,
      menus: [],
      last_evaluated_key: null,
    } as MenuListState;
  },

  computed: {
    keyword() {
      if (this.$route.query._k) {
        return this.$route.query._k.toString();
      }
      return null;
    },
  },

  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      console.log(`refresh(${this.keyword})`);
      this.is_loading = true;
      setTimeout(async () => {
        try {
          const result = await Menu.search(
            this.$store.state.temple_id,
            false,
            this.keyword
          );

          const items: MenuItem[] = [];

          result.menus.forEach((menu) => {
            const temp = menu.temple_prices[this.$store.state.temple_id];
            console.log(temp);

            let price: number | null = null;
            if (temp) {
              price = parseInt(temp);
            }

            items.push({
              menu: menu,
              is_enable: !!price,
              price: price,
            });
          });

          this.menus = items;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.is_loading = false;
        }
      });
    },

    onKeywordChanged(e: Event) {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }
      this.$router.push({
        path: "/templemenus",
        query: {
          _k: e.target.value,
        },
      });
    },

    onSave(item: MenuItem) {
      console.log(item);
      this.is_loading = true;
      setTimeout(async () => {
        try {
          item.is_enable = !item.is_enable;

          if (item.is_enable && !item.price) {
            alert("布施を入力してください.");
            item.is_enable = !item.is_enable;
            return;
          }

          const success = await Menu.updatePrice(
            this.$store.state.temple_id,
            item.menu.id,
            item.is_enable ? item.price : null
          );

          if (success) {
            this.refresh();
          }
        } finally {
          this.is_loading = false;
        }
      });
    },
  },
});
