import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["autocomplete", "placeholder", "name", "id", "type", "value", "step"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs text-pink-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("label", {
      for: _ctx.id,
      class: "block px-2 text-sm font-medium text-gray-700"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1), [
      [_vShow, _ctx.label]
    ]),
    _createElementVNode("input", {
      autocomplete: _ctx.autocomplete,
      placeholder: _ctx.placeholder,
      name: _ctx.name,
      id: _ctx.id,
      type: _ctx.type || `text`,
      value: _ctx.modelValue,
      step: _ctx.step,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChanged($event))),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
      class: _normalizeClass(["block w-full px-2 pt-1 pb-2 mt-1 placeholder-gray-400 border-b appearance-none focus:outline-none", {
        'border-gray-300': !_ctx.error_message,
        'border-red-300': !!_ctx.error_message,
      }])
    }, null, 42, _hoisted_2),
    (_ctx.error_message)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.error_message || ""), 1))
      : _createCommentVNode("", true)
  ]))
}