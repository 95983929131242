
import { defineComponent } from "vue";
import moment from "moment";
import TempleLayout from "@/components/TempleLayout.vue";
import Billing from "@/models/Billing";

interface TempleBillingListState {
  // is_loading: boolean;
  is_confirming: boolean;
  operation: string;
  billings: Billing[];
  last_evaluated_key: string | null;
}

export default defineComponent({
  components: { TempleLayout },

  data() {
    return {
      // is_loading: false,
      is_confirming: false,
      operation: "",
      billings: [],
      last_evaluated_key: null,
    } as TempleBillingListState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    formatStatus(status: string) {
      if (status == "authorized") {
        return "申込中";
      }
      return "";
    },

    refresh() {
      console.log(`refresh()`);

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await Billing.search_by_temple_id(
            this.$store.state.temple_id
          );
          this.billings = result.billings;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    proceedOperation() {
      this.operation = "";
      this.is_confirming = false;
      this.refresh();
    },

    cancelOperation() {
      this.operation = "";
      this.is_confirming = false;
    },
  },
});
