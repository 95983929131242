import { Auth } from "aws-amplify";
import axios from "axios";

interface OrderJson {
  id: string;
  order_number: string;
  applyment_method: string;
  temple_id: string;
  temple_name: string;
  menu_id: string;
  menu_type: string;
  menu_name: string;
  menu_accept_text: boolean;
  menu_accept_photo: boolean;
  menu_accept_video: boolean;
  menu_accept_bone: boolean;
  menu_accept_syakyo: boolean;
  menu_upper_limit_file_size: number | null;
  menu_upper_limit_file_count: number | null;
  menu_attached_byobu: boolean;
  menu_attached_memorial_goods: boolean;
  price: string;
  customer_id: string | null;
  customer_name: string | null;
  stripe_payment_intent_id: string | null;
  applicant_family_name: string | null;
  applicant_given_name: string | null;
  applicant_family_name_kana: string | null;
  applicant_given_name_kana: string | null;
  applicant_postcode: string | null;
  applicant_prefecture: string | null;
  applicant_city: string | null;
  applicant_address: string | null;
  applicant_building: string | null;
  applicant_telephone: string | null;
  applicant_email: string | null;
  status: string;
  attributes: Map<string, string | number>;
  resource_urls: string[];
  report_url: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface OrderSearchResult {
  orders: Order[];
  last_evaluated_key: string | null;
}

export default class Order {
  id: string;
  order_number: string;
  applyment_method: string;
  temple_id: string;
  temple_name: string;
  menu_id: string;
  menu_type: string;
  menu_name: string;
  menu_accept_text: boolean;
  menu_accept_photo: boolean;
  menu_accept_video: boolean;
  menu_accept_bone: boolean;
  menu_accept_syakyo: boolean;
  menu_upper_limit_file_size: number | null;
  menu_upper_limit_file_count: number | null;
  menu_attached_byobu: boolean;
  menu_attached_memorial_goods: boolean;
  price: string;
  customer_id: string | null;
  customer_name: string | null;
  stripe_payment_intent_id: string | null;
  applicant_family_name: string | null;
  applicant_given_name: string | null;
  applicant_family_name_kana: string | null;
  applicant_given_name_kana: string | null;
  applicant_postcode: string | null;
  applicant_prefecture: string | null;
  applicant_city: string | null;
  applicant_address: string | null;
  applicant_building: string | null;
  applicant_telephone: string | null;
  applicant_email: string | null;
  status: string;
  attributes: Map<string, string | number>;
  resource_urls: string[];
  report_url: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  is_selected: boolean;

  name(): string {
    if (this.customer_name) {
      return this.customer_name;
    }

    return `${this.applicant_family_name} ${this.applicant_given_name}`;
  }

  state_name(): string {
    console.log("a");
    if (this.status == "wait_payment") {
      return "決済処理中";
    }
    if (this.status == "accepting") {
      if (this.menu_type == "for_wish") {
        if (this.menu_accept_syakyo && !this.attributes["syakyo"]) {
          return "データアップロード中";
        }
      }
      return "祈願・供養待ち";
    }
    if (this.status == "accepted") {
      return "打上準備中";
    }

    return this.menu_type == "wish" ? "宇宙祈願中" : "宇宙供養中";
  }

  constructor(
    id: string,
    order_number: string,
    applyment_method: string,
    temple_id: string,
    temple_name: string,
    menu_id: string,
    menu_type: string,
    menu_name: string,
    menu_accept_text: boolean,
    menu_accept_photo: boolean,
    menu_accept_video: boolean,
    menu_accept_bone: boolean,
    menu_accept_syakyo: boolean,
    menu_upper_limit_file_size: number | null,
    menu_upper_limit_file_count: number | null,
    menu_attached_byobu: boolean,
    menu_attached_memorial_goods: boolean,
    price: string,
    customer_id: string | null,
    customer_name: string | null,
    stripe_payment_intent_id: string | null,
    applicant_family_name: string | null,
    applicant_given_name: string | null,
    applicant_family_name_kana: string | null,
    applicant_given_name_kana: string | null,
    applicant_postcode: string | null,
    applicant_prefecture: string | null,
    applicant_city: string | null,
    applicant_address: string | null,
    applicant_building: string | null,
    applicant_telephone: string | null,
    applicant_email: string | null,
    status: string,
    attributes: Map<string, string | number>,
    resource_urls: string[],
    report_url: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.order_number = order_number;
    this.applyment_method = applyment_method;
    this.temple_id = temple_id;
    this.temple_name = temple_name;
    this.menu_id = menu_id;
    this.menu_type = menu_type;
    this.menu_name = menu_name;
    this.menu_accept_text = menu_accept_text;
    this.menu_accept_photo = menu_accept_photo;
    this.menu_accept_video = menu_accept_video;
    this.menu_accept_bone = menu_accept_bone;
    this.menu_accept_syakyo = menu_accept_syakyo;
    this.menu_upper_limit_file_size = menu_upper_limit_file_size;
    this.menu_upper_limit_file_count = menu_upper_limit_file_count;
    this.menu_attached_byobu = menu_attached_byobu;
    this.menu_attached_memorial_goods = menu_attached_memorial_goods;
    this.price = price;
    this.customer_id = customer_id;
    this.customer_name = customer_name;
    this.stripe_payment_intent_id = stripe_payment_intent_id;
    this.applicant_family_name = applicant_family_name;
    this.applicant_given_name = applicant_given_name;
    this.applicant_family_name_kana = applicant_family_name_kana;
    this.applicant_given_name_kana = applicant_given_name_kana;
    this.applicant_postcode = applicant_postcode;
    this.applicant_prefecture = applicant_prefecture;
    this.applicant_city = applicant_city;
    this.applicant_address = applicant_address;
    this.applicant_building = applicant_building;
    this.applicant_telephone = applicant_telephone;
    this.applicant_email = applicant_email;
    this.status = status;
    this.attributes = attributes;
    this.resource_urls = resource_urls;
    this.report_url = report_url;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;

    this.is_selected = false;
  }

  static async get(id: string): Promise<Order> {
    console.log(`Order.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/temples/${id}`,
      config
    );

    const json = response.data.data as OrderJson;

    return new Order(
      json.id,
      json.order_number,
      json.applyment_method,
      json.temple_id,
      json.temple_name,
      json.menu_id,
      json.menu_type,
      json.menu_name,
      json.menu_accept_text,
      json.menu_accept_photo,
      json.menu_accept_video,
      json.menu_accept_bone,
      json.menu_accept_syakyo,
      json.menu_upper_limit_file_size,
      json.menu_upper_limit_file_count,
      json.menu_attached_byobu,
      json.menu_attached_memorial_goods,
      json.price,
      json.customer_id,
      json.customer_name,
      json.stripe_payment_intent_id,
      json.applicant_family_name,
      json.applicant_given_name,
      json.applicant_family_name_kana,
      json.applicant_given_name_kana,
      json.applicant_postcode,
      json.applicant_prefecture,
      json.applicant_city,
      json.applicant_address,
      json.applicant_building,
      json.applicant_telephone,
      json.applicant_email,
      json.status,
      json.attributes,
      json.resource_urls,
      json.report_url,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    temple_id: string | null,
    keyword: string | null,
    start: string | null,
    end: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<OrderSearchResult> {
    console.log(
      `Order.search(${keyword}, ${start}, ${end}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        temple_id: temple_id,
        keyword,
        start,
        end,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/orders`,
      config
    );

    const list: Order[] = [];
    response.data.data.forEach((json: OrderJson) => {
      list.push(
        new Order(
          json.id,
          json.order_number,
          json.applyment_method,
          json.temple_id,
          json.temple_name,
          json.menu_id,
          json.menu_type,
          json.menu_name,
          json.menu_accept_text,
          json.menu_accept_photo,
          json.menu_accept_video,
          json.menu_accept_bone,
          json.menu_accept_syakyo,
          json.menu_upper_limit_file_size,
          json.menu_upper_limit_file_count,
          json.menu_attached_byobu,
          json.menu_attached_memorial_goods,
          json.price,
          json.customer_id,
          json.customer_name,
          json.stripe_payment_intent_id,
          json.applicant_family_name,
          json.applicant_given_name,
          json.applicant_family_name_kana,
          json.applicant_given_name_kana,
          json.applicant_postcode,
          json.applicant_prefecture,
          json.applicant_city,
          json.applicant_address,
          json.applicant_building,
          json.applicant_telephone,
          json.applicant_email,
          json.status,
          json.attributes,
          json.resource_urls,
          json.report_url,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      orders: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    temple_id: string,
    menu_id: string,
    applicant_family_name: string,
    applicant_given_name: string,
    applicant_family_name_kana: string,
    applicant_given_name_kana: string,
    applicant_postcode: string,
    applicant_prefecture: string,
    applicant_city: string,
    applicant_address: string,
    applicant_building: string | null,
    applicant_telephone: string,
    applicant_email: string | null,
    attributes: Map<string, string | number | null>
  ): Promise<Order> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/orders`,
      {
        applyment_method: "manual",
        temple_id: temple_id,
        menu_id: menu_id,
        applicant_family_name: applicant_family_name,
        applicant_given_name: applicant_given_name,
        applicant_family_name_kana: applicant_family_name_kana,
        applicant_given_name_kana: applicant_given_name_kana,
        applicant_postcode: applicant_postcode,
        applicant_prefecture: applicant_prefecture,
        applicant_city: applicant_city,
        applicant_address: applicant_address,
        applicant_building: applicant_building,
        applicant_telephone: applicant_telephone,
        applicant_email: applicant_email,
        attributes: attributes,
      },
      config
    );

    console.log(response);

    const json = response.data.data as OrderJson;

    return new Order(
      json.id,
      json.order_number,
      json.applyment_method,
      json.temple_id,
      json.temple_name,
      json.menu_id,
      json.menu_type,
      json.menu_name,
      json.menu_accept_text,
      json.menu_accept_photo,
      json.menu_accept_video,
      json.menu_accept_bone,
      json.menu_accept_syakyo,
      json.menu_upper_limit_file_size,
      json.menu_upper_limit_file_count,
      json.menu_attached_byobu,
      json.menu_attached_memorial_goods,
      json.price,
      json.customer_id,
      json.customer_name,
      json.stripe_payment_intent_id,
      json.applicant_family_name,
      json.applicant_given_name,
      json.applicant_family_name_kana,
      json.applicant_given_name_kana,
      json.applicant_postcode,
      json.applicant_prefecture,
      json.applicant_city,
      json.applicant_address,
      json.applicant_building,
      json.applicant_telephone,
      json.applicant_email,
      json.status,
      json.attributes,
      json.resource_urls,
      json.report_url,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async updateStates(state: string, ids: string[]): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/orders/states`,
      {
        "state": state,
        "ids": ids,
      },
      config
    );

    console.log(response);

    // const json = response.data.data as OrderJson;
  }

  static async cancel(id: string): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/orders/${id}`,
      config
    );

    console.log(response);

    // const json = response.data.data as OrderJson;
  }
}
