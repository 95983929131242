import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Auth, Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";

import store from "@/store";

import SystemOrderList from "@/pages/SystemOrderList.vue";
import TempleList from "../pages/TempleList.vue";
import TempleEdit from "../pages/TempleEdit.vue";
import MenuList from "../pages/MenuList.vue";
import MenuEdit from "../pages/MenuEdit.vue";
import SuspectionCommentList from "../pages/SuspectionCommentList.vue";
import SystemBillingList from "../pages/SystemBillingList.vue";

import TempleOrderList from "../pages/TempleOrderList.vue";
import InputWish from "../pages/InputWish.vue";
import InputPray from "../pages/InputPray.vue";
import InformationList from "../pages/InformationList.vue";
import InformationDetail from "../pages/InformationDetail.vue";
import InformationEdit from "../pages/InformationEdit.vue";
import DecedentList from "../pages/DecedentList.vue";
import DecedentDetail from "../pages/DecedentDetail.vue";
import DecedentEdit from "../pages/DecedentEdit.vue";
import TempleMenuList from "@/pages/TempleMenuList.vue";
import TempleBillingList from "@/pages/TempleBillingList.vue";
import TempleAlbumList from "../pages/TempleAlbumList.vue";
import TempleSetting from "../pages/TempleSetting.vue";

import Login from "../pages/Login.vue";
import ResetPassword from "../pages/ResetPassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/temples",
    name: "TempleList",
    component: TempleList,
    meta: { context: "system" },
  },
  {
    path: "/temples/register",
    name: "TempleRegister",
    component: TempleEdit,
    meta: { context: "system" },
  },
  {
    path: "/temples/:temple_id/edit",
    name: "TempleEdit",
    component: TempleEdit,
    meta: { context: "system" },
  },
  {
    path: "/billings/system",
    name: "SystemBillingList",
    component: SystemBillingList,
    meta: { context: "system" },
  },
  {
    path: "/menus",
    name: "MenuList",
    component: MenuList,
    meta: { context: "system" },
  },
  {
    path: "/menus/register",
    name: "MenuRegister",
    component: MenuEdit,
    meta: { context: "system" },
  },
  {
    path: "/menus/:menu_id/edit",
    name: "MenuEdit",
    component: MenuEdit,
    meta: { context: "system" },
  },
  {
    path: "/suspections",
    name: "SuspectionCommentList",
    component: SuspectionCommentList,
    meta: { context: "system" },
  },
  {
    path: "/orders/system",
    name: "SystemOrderList",
    component: SystemOrderList,
    meta: { context: "system" },
  },
  {
    path: "/orders/temple",
    name: "TempleOrderList",
    component: TempleOrderList,
    meta: { context: "temple" },
  },
  {
    path: "/orders/wish",
    name: "InputWish",
    component: InputWish,
    meta: { context: "temple" },
  },
  {
    path: "/orders/pray",
    name: "InputPray",
    component: InputPray,
    meta: { context: "temple" },
  },
  {
    path: "/information",
    name: "InformationList",
    component: InformationList,
    meta: { context: "temple" },
  },
  {
    path: "/information/:timestamp/detail",
    name: "InformationDetail",
    component: InformationDetail,
    meta: { context: "temple" },
  },
  {
    path: "/information/register",
    name: "InformationRegister",
    component: InformationEdit,
    meta: { context: "temple" },
  },
  {
    path: "/information/:timestamp/edit",
    name: "InformationEdit",
    component: InformationEdit,
    meta: { context: "temple" },
  },
  {
    path: "/decedents",
    name: "DecedentList",
    component: DecedentList,
    meta: { context: "temple" },
  },
  {
    path: "/decedents/:decedent_id",
    name: "DecedentDetail",
    component: DecedentDetail,
    meta: { context: "temple" },
  },
  {
    path: "/decedents/register",
    name: "DecedentRegister",
    component: DecedentEdit,
    meta: { context: "temple" },
  },
  {
    path: "/decedents/:decedent_id/edit",
    name: "DecedentEdit",
    component: DecedentEdit,
    meta: { context: "temple" },
  },
  {
    path: "/templemenus",
    name: "TempleMenuList",
    component: TempleMenuList,
    meta: { context: "temple" },
  },
  {
    path: "/billings/temple",
    name: "TempleBillingList",
    component: TempleBillingList,
    meta: { context: "temple" },
  },
  {
    path: "/albums",
    name: "TempleAlbumList",
    component: TempleAlbumList,
    meta: { context: "temple" },
  },
  {
    path: "/settings",
    name: "TempleSetting",
    component: TempleSetting,
    meta: { context: "temple" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { context: "auth" },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { context: "auth" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userInfo = await Auth.currentUserInfo();

  console.log(userInfo);

  if (!userInfo && (to.fullPath !== "/login" && to.fullPath !== "/resetpassword")) {
    next("/login");
    return;
  }

  if (userInfo && (to.fullPath == "/login" || to.fullPath == "/resetpassword" || to.fullPath == "/")) {
    const role = userInfo.attributes["custom:role"];
    console.log(role);
    if (role == "temple") {
      next("/orders/temple");
    } else {
      next("/orders/system");
    }
    return;
  }

  next();
});

const authListener: HubCallback = ({ payload: { event /*, data */ } }) => {
  console.log(event);
  switch (event) {
    case "signIn":
      // setUser({
      //   username: data.username,
      //   token: data.signInUserSession.accessToken,
      // });
      store.dispatch("signIn");
      router.replace("/");
      break;
    case "signOut":
      router.replace("/login");
      break;
  }
};

Hub.listen("auth", authListener);

export default router;
