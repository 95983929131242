
import { defineComponent } from "vue";
import moment from "moment";
import SystemLayout from "@/components/SystemLayout.vue";
import Datepicker from "@vuepic/vue-datepicker";
import Order from "@/models/Order";

interface OrderListState {
  // is_loading: boolean;
  is_confirming: boolean;
  operation: string;
  orders: Order[];
  last_evaluated_key: string | null;
}

export default defineComponent({
  components: { SystemLayout, Datepicker },

  data() {
    return {
      // is_loading: false,
      is_confirming: false,
      operation: "",
      orders: [],
      last_evaluated_key: null,
    } as OrderListState;
  },

  computed: {
    date() {
      if (this.$route.query._d) {
        return this.$route.query._d.toString();
      }
      return null;
    },

    keyword: {
      get() {
        if (this.$route.query._k) {
          return this.$route.query._k.toString();
        }
        return null;
      },
      set(value: string) {
        this.$router.replace({
          path: "/orders/system",
          query: {
            _k: value,
            _s: this.start,
            _e: this.end,
          },
        });
      },
    },

    start: {
      get(): string | null {
        if (this.$route.query._s) {
          return this.$route.query._s.toString();
        }
        return null;
      },
      set(date: string) {
        console.log(date);
        this.$router.replace({
          path: "/orders/system",
          query: {
            _k: this.keyword,
            _s: moment(date).format("YYYY-MM-DD"),
            _e: this.end ? moment(this.end).format("YYYY-MM-DD") : "",
          },
        });
      },
    },

    end: {
      get(): string | null {
        if (this.$route.query._e) {
          return this.$route.query._e.toString();
        }
        return null;
      },
      set(date: string) {
        console.log(date);
        this.$router.replace({
          path: "/orders/system",
          query: {
            _k: this.keyword,
            _s: this.start ? moment(this.start).format("YYYY-MM-DD") : "",
            _e: moment(date).format("YYYY-MM-DD"),
          },
        });
      },
    },

    operation_title() {
      if (this.operation == "accept") {
        return "申込受付";
      } else if (this.operation == "cancel") {
        return "申込キャンセル";
      }

      return "";
    },

    operation_description() {
      if (this.operation == "accept") {
        return "選択された申込の受付を行います。よろしいですか？";
      } else if (this.operation == "cancel") {
        return "選択された申込のキャンセルを行います。よろしいですか？";
      }

      return "";
    },
  },

  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    formatStatus(status: string) {
      if (status == "authorized") {
        return "申込中";
      }
      return "";
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }
      console.log(`refresh(${this.keyword})`);

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await Order.search(
            null, // this.$store.state.temple_id,
            this.keyword,
            this.start ? moment(this.start).format("YYYY-MM-DD") : null,
            this.end ? moment(this.end).format("YYYY-MM-DD") : null
          );
          this.orders = result.orders;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onOperationChanged() {
      console.log(this.operation);

      const filter = this.orders.filter((o) => o.is_selected);
      if (!filter.length) {
        this.operation = "";
        return;
      }

      console.log(filter);

      this.is_confirming = true;
    },

    proceedOperation() {
      this.operation = "";
      this.is_confirming = false;
      this.refresh();
    },

    cancelOperation() {
      this.operation = "";
      this.is_confirming = false;
    },
  },
});
