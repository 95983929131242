import * as yup from "yup";

yup.setLocale({
  string: {
    email: "メールアドレスの形式が不正です",
    url: "URLの形式が不正です",
  },
});

yup.addMethod<yup.StringSchema>(yup.string, "kana", function () {
  return this.test(
    "kana",
    "カタカナで入力して下さい",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/^[ァ-ヶー　 ]+$/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "phonenumber", function () {
  return this.test(
    "phonenumber",
    "電話番号が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/\d{2,4}-?\d{2,4}-?\d{4}/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "postcode", function () {
  return this.test(
    "postcode",
    "郵便番号が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/^[0-9]{3}-?[0-9]{4}$/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "accountno", function () {
  return this.test(
    "accountno",
    "口座番号が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/^[0-9]{3,7}$/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "month", function () {
  return this.test(
    "month",
    "月が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(
        /^(0?1|0?2|0?3|0?4|0?5|0?6|0?7|0?8|0?9|10|11|12)$/
      );
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "day", function () {
  return this.test(
    "day",
    "日が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(
        /^(0?1|0?2|0?3|0?4|0?5|0?6|0?7|0?8|0?9|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31)$/
      );
      return !!match;
    }
  );
});
