import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";

interface MenuJson {
  id: string;
  menu_type: string;
  temple_id: string | null;
  temple_name: string | null;
  name: string;
  main_image_url: string | null;
  description: string;
  // wholesale_price: number;
  // default_list_price: number;
  temple_prices: Map<string, number>;
  available_from: string | null;
  available_to: string | null;
  redirect_url: string | null;
  attributes: string[];
  accept_text: boolean;
  accept_photo: boolean;
  accept_video: boolean;
  accept_bone: boolean;
  accept_syakyo: boolean;
  upper_limit_file_size: number | null;
  upper_limit_file_count: number | null;
  attached_byobu: boolean;
  attached_memorial_goods: boolean;
  manual_order_only: boolean;
  display_order: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface MenuSearchResult {
  menus: Menu[];
  last_evaluated_key: string | null;
}

export default class Menu {
  id: string;
  menu_type: string;
  temple_id: string | null;
  temple_name: string | null;
  name: string;
  main_image_url: string | null;
  description: string;
  // wholesale_price: number;
  // default_list_price: number;
  temple_prices: Map<string, number>;
  available_from: Date | null;
  available_to: Date | null;
  redirect_url: string | null;
  attributes: string[];
  accept_text: boolean;
  accept_photo: boolean;
  accept_video: boolean;
  accept_bone: boolean;
  accept_syakyo: boolean;
  upper_limit_file_size: number | null;
  upper_limit_file_count: number | null;
  attached_byobu: boolean;
  attached_memorial_goods: boolean;
  manual_order_only: boolean;
  display_order: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  is_selected: boolean;

  constructor(
    id: string,
    menu_type: string,
    temple_id: string | null,
    temple_name: string | null,
    name: string,
    main_image_url: string | null,
    description: string,
    // wholesale_price: number,
    // default_list_price: number,
    temple_prices: Map<string, number>,
    available_from: Date | null,
    available_to: Date | null,
    redirect_url: string | null,
    attributes: string[],
    accept_text: boolean,
    accept_photo: boolean,
    accept_video: boolean,
    accept_bone: boolean,
    accept_syakyo: boolean,
    upper_limit_file_size: number | null,
    upper_limit_file_count: number | null,
    attached_byobu: boolean,
    attached_memorial_goods: boolean,
    manual_order_only: boolean,
    display_order: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.menu_type = menu_type;
    (this.temple_id = temple_id),
      (this.temple_name = temple_name),
      (this.name = name);
    this.main_image_url = main_image_url;
    this.description = description;
    // this.wholesale_price = wholesale_price;
    // this.default_list_price = default_list_price;
    this.temple_prices = temple_prices;
    this.available_from = available_from;
    this.available_to = available_to;
    this.redirect_url = redirect_url;
    this.attributes = attributes;
    this.accept_text = accept_text;
    this.accept_photo = accept_photo;
    this.accept_video = accept_video;
    this.accept_bone = accept_bone;
    this.accept_syakyo = accept_syakyo;
    this.upper_limit_file_size = upper_limit_file_size;
    this.upper_limit_file_count = upper_limit_file_count;
    this.attached_byobu = attached_byobu;
    this.attached_memorial_goods = attached_memorial_goods;
    this.manual_order_only = manual_order_only;
    this.display_order = display_order;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;

    this.is_selected = false;
  }

  static async get(id: string): Promise<Menu> {
    console.log(`Menu.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/menus/${id}`,
      config
    );

    const json = response.data.data as MenuJson;

    return new Menu(
      json.id,
      json.menu_type,
      json.temple_id,
      json.temple_name,
      json.name,
      json.main_image_url,
      json.description,
      // json.wholesale_price,
      // json.default_list_price,
      json.temple_prices,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.redirect_url,
      json.attributes,
      json.accept_text,
      json.accept_photo,
      json.accept_video,
      json.accept_bone,
      json.accept_syakyo,
      json.upper_limit_file_size,
      json.upper_limit_file_count,
      json.attached_byobu,
      json.attached_memorial_goods,
      json.manual_order_only,
      json.display_order,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    temple_id: string | null,
    include_deleted: boolean,
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<MenuSearchResult> {
    console.log(`Menu.search(${temple_id}, ${include_deleted}, ${keyword}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        temple_id,
        include_deleted,
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/menus`,
      config
    );

    const list: Menu[] = [];
    response.data.data.forEach((json: MenuJson) => {
      list.push(
        new Menu(
          json.id,
          json.menu_type,
          json.temple_id,
          json.temple_name,
          json.name,
          json.main_image_url,
          json.description,
          // json.wholesale_price,
          // json.default_list_price,
          json.temple_prices,
          json.available_from ? new Date(json.available_from) : null,
          json.available_to ? new Date(json.available_to) : null,
          json.redirect_url,
          json.attributes,
          json.accept_text,
          json.accept_photo,
          json.accept_video,
          json.accept_bone,
          json.accept_syakyo,
          json.upper_limit_file_size,
          json.upper_limit_file_count,
          json.attached_byobu,
          json.attached_memorial_goods,
          json.manual_order_only,
          json.display_order,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      menus: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    menu_type: string,
    temple_id: string | null,
    wholesale_price: string | null,
    name: string,
    main_image_url: string | null,
    description: string,
    // wholesale_price: number,
    // default_list_price: number,
    available_from: Date | null,
    available_to: Date | null,
    redirect_url: string | null,
    accept_text: boolean,
    accept_photo: boolean,
    accept_video: boolean,
    accept_bone: boolean,
    accept_syakyo: boolean,
    upper_limit_file_size: number | null,
    upper_limit_file_count: number | null,
    attached_byobu: boolean,
    attached_memorial_goods: boolean,
    manual_order_only: boolean
  ): Promise<Menu> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/menus`,
      {
        menu_type: menu_type,
        temple_id: temple_id,
        wholesale_price: wholesale_price ? parseInt(wholesale_price) : null,
        name: name,
        main_image_url: main_image_url,
        description: description,
        // wholesale_price: wholesale_price,
        // default_list_price: default_list_price,
        available_from: available_from
          ? moment(available_from).format("YYYY-MM-DD")
          : null,
        available_to: available_to
          ? moment(available_to).format("YYYY-MM-DD")
          : null,
        redirect_url: redirect_url,
        accept_text: accept_text,
        accept_photo: accept_photo,
        accept_video: accept_video,
        accept_bone: accept_bone,
        accept_syakyo: accept_syakyo,
        upper_limit_file_size: upper_limit_file_size,
        upper_limit_file_count: upper_limit_file_count,
        attached_byobu: attached_byobu,
        attached_memorial_goods: attached_memorial_goods,
        manual_order_only: manual_order_only,
        attributes: [],
      },
      config
    );

    console.log(response);

    const json = response.data as MenuJson;

    return new Menu(
      json.id,
      json.menu_type,
      json.temple_id,
      json.temple_name,
      json.name,
      json.main_image_url,
      json.description,
      // json.wholesale_price,
      // json.default_list_price,
      json.temple_prices,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.redirect_url,
      json.attributes,
      json.accept_text,
      json.accept_photo,
      json.accept_video,
      json.accept_bone,
      json.accept_syakyo,
      json.upper_limit_file_size,
      json.upper_limit_file_count,
      json.attached_byobu,
      json.attached_memorial_goods,
      json.manual_order_only,
      json.display_order,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async update(
    id: string,
    name: string,
    main_image_url: string | null,
    description: string,
    // wholesale_price: number,
    // default_list_price: number,
    available_from: Date | null,
    available_to: Date | null,
    redirect_url: string | null,
    accept_text: boolean,
    accept_photo: boolean,
    accept_video: boolean,
    accept_bone: boolean,
    accept_syakyo: boolean,
    upper_limit_file_size: number | null,
    upper_limit_file_count: number | null,
    attached_byobu: boolean,
    attached_memorial_goods: boolean,
    manual_order_only: boolean
  ): Promise<Menu> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/menus/${id}`,
      {
        name: name,
        main_image_url: main_image_url,
        description: description,
        // wholesale_price: wholesale_price,
        // default_list_price: default_list_price,
        available_from: available_from
          ? moment(available_from).format("YYYY-MM-DD")
          : null,
        available_to: available_to
          ? moment(available_to).format("YYYY-MM-DD")
          : null,
        redirect_url: redirect_url,
        accept_text: accept_text,
        accept_photo: accept_photo,
        accept_video: accept_video,
        accept_bone: accept_bone,
        accept_syakyo: accept_syakyo,
        upper_limit_file_size: upper_limit_file_size,
        upper_limit_file_count: upper_limit_file_count,
        attached_byobu: attached_byobu,
        attached_memorial_goods: attached_memorial_goods,
        manual_order_only: manual_order_only,
        attributes: [],
      },
      config
    );

    console.log(response);

    const json = response.data as MenuJson;

    return new Menu(
      json.id,
      json.menu_type,
      json.temple_id,
      json.temple_name,
      json.name,
      json.main_image_url,
      json.description,
      // json.wholesale_price,
      // json.default_list_price,
      json.temple_prices,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.redirect_url,
      json.attributes,
      json.accept_text,
      json.accept_photo,
      json.accept_video,
      json.accept_bone,
      json.accept_syakyo,
      json.upper_limit_file_size,
      json.upper_limit_file_count,
      json.attached_byobu,
      json.attached_memorial_goods,
      json.manual_order_only,
      json.display_order,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Menu> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/menus/${id}`,
      config
    );

    console.log(response);

    const json = response.data as MenuJson;

    return new Menu(
      json.id,
      json.menu_type,
      json.temple_id,
      json.temple_name,
      json.name,
      json.main_image_url,
      json.description,
      // json.wholesale_price,
      // json.default_list_price,
      json.temple_prices,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.redirect_url,
      json.attributes,
      json.accept_text,
      json.accept_photo,
      json.accept_video,
      json.accept_bone,
      json.accept_syakyo,
      json.upper_limit_file_size,
      json.upper_limit_file_count,
      json.attached_byobu,
      json.attached_memorial_goods,
      json.manual_order_only,
      json.display_order,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async updatePrice(
    temple_id: string,
    menu_id: string,
    price: number | null
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/prices`,
      {
        temple_id: temple_id,
        menu_id: menu_id,
        price: price,
      },
      config
    );

    console.log(response);

    const json = response.data as MenuJson;

    return true;

    // return new Menu(
    //   json.id,
    //   json.menu_type,
    //   json.name,
    //   json.main_image_url,
    //   json.description,
    //   // json.wholesale_price,
    //   // json.default_list_price,
    //   json.temple_prices,
    //   json.available_from ? new Date(json.available_from) : null,
    //   json.available_to ? new Date(json.available_to) : null,
    //   json.attributes,
    //   json.accept_photo,
    //   json.accept_video,
    //   json.accept_bone,
    //   json.accept_syakyo,
    //   json.upper_limit_file_size,
    //   json.upper_limit_file_count,
    //   json.attached_byobu,
    //   json.attached_memorial_goods,
    //   new Date(response.data.created_at),
    //   new Date(response.data.updated_at),
    //   response.data.deleted_at ? new Date(response.data.deleted_at) : null
    // );
  }
}
