import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";

interface AlbumJson {
  target_id: string;
  timestamp: number;
  publisher_id: string;
  publisher_name: string;
  publisher_username: string;
  publisher_selfie_image_url: string;
  media_url: MediaResourceJson;
  comment: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface AlbumSearchResult {
  albums: Album[];
  last_evaluated_key: string | null;
}

export default class Album {
  target_id: string;
  timestamp: number;
  publisher_id: string;
  publisher_name: string;
  publisher_username: string;
  publisher_selfie_image_url: string;
  media_url: MediaResource;
  comment: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  is_sending = false;

  constructor(
    target_id: string,
    timestamp: number,
    publisher_id: string,
    publisher_name: string,
    publisher_username: string,
    publisher_selfie_image_url: string,
    media_url: MediaResource,
    comment: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.target_id = target_id;
    this.timestamp = timestamp;
    this.publisher_id = publisher_id;
    this.publisher_name = publisher_name;
    this.publisher_username = publisher_username;
    this.publisher_selfie_image_url = publisher_selfie_image_url;
    this.media_url = media_url;
    this.comment = comment;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  // static async get(target_id: string, timestamp: number): Promise<Album> {
  //   console.log(`Album.get(${target_id}, ${timestamp})`);
  //   const user = await Auth.currentAuthenticatedUser();

  //   console.log(user);

  //   const config = {
  //     headers: {
  //       Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
  //     },
  //   };

  //   const response = await axios.get(
  //     `${process.env.VUE_APP_API_HOST}/albums/${target_id}_${timestamp}`,
  //     config
  //   );

  //   const json = response.data.data as AlbumJson;

  //   return new Album(
  //     json.target_id,
  //     json.timestamp,
  //     json.publisher_id,
  //     json.publisher_name,
  //     json.publisher_username,
  //     json.publisher_selfie_image_url,
  //     MediaResource.fromJson(json.media_url),
  //     json.comment,
  //     new Date(json.created_at),
  //     new Date(json.updated_at),
  //     json.deleted_at ? new Date(json.deleted_at) : null
  //   );
  // }

  static async searchByTempleId(
    temple_id: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<AlbumSearchResult> {
    console.log(
      `Album.searchByTempleId(${temple_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        target_id: `temple_${temple_id}`,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/albums`,
      config
    );

    const list: Album[] = [];
    response.data.data.forEach((json: AlbumJson) => {
      list.push(
        new Album(
          json.target_id,
          json.timestamp,
          json.publisher_id,
          json.publisher_name,
          json.publisher_username,
          json.publisher_selfie_image_url,
          MediaResource.fromJson(json.media_url),
          json.comment,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      albums: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async searchByDecedentId(
    decedent_id: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<AlbumSearchResult> {
    console.log(
      `Album.searchByDecedentId(${decedent_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        target_id: `decedent_${decedent_id}`,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/albums`,
      config
    );

    const list: Album[] = [];
    response.data.data.forEach((json: AlbumJson) => {
      list.push(
        new Album(
          json.target_id,
          json.timestamp,
          json.publisher_id,
          json.publisher_name,
          json.publisher_username,
          json.publisher_selfie_image_url,
          MediaResource.fromJson(json.media_url),
          json.comment,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      albums: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async registerForTemple(
    temple_id: string,
    media_url: MediaResource,
    comment: string
  ): Promise<Album> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/albums`,
      {
        target_id: `temple_${temple_id}`,
        temple_id: temple_id,
        media_url: media_url,
        comment: comment,
      },
      config
    );

    console.log(response);

    const json = response.data.data as AlbumJson;

    console.log(json.media_url);

    return new Album(
      json.target_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      MediaResource.fromJson(json.media_url),
      json.comment,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async registerForDecedent(
    decedent_id: string,
    temple_id: string,
    media_url: MediaResource,
    comment: string | null
  ): Promise<Album> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/albums`,
      {
        target_id: `decedent_${decedent_id}`,
        temple_id: temple_id,
        media_url: media_url,
        comment: comment,
      },
      config
    );

    console.log(response);

    const json = response.data.data as AlbumJson;

    console.log(json.media_url);

    return new Album(
      json.target_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      MediaResource.fromJson(json.media_url),
      json.comment,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  // static async update(
  //   temple_id: string,
  //   timestamp: number,
  //   title: string,
  //   body: string,
  //   available_from: Date | null,
  //   available_to: Date | null
  // ): Promise<Album> {
  //   const user = await Auth.currentAuthenticatedUser();

  //   const config = {
  //     headers: {
  //       Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
  //     },
  //   };

  //   const response = await axios.put(
  //     `${process.env.VUE_APP_API_HOST}/albums/${temple_id}_${timestamp}`,
  //     {
  //       temple_id: temple_id,
  //       timestamp: timestamp,
  //       title: title,
  //       body: body,
  //       available_from:
  //         available_from != null
  //           ? moment(available_from).format("YYYY-MM-DD")
  //           : null,
  //       available_to:
  //         available_to != null
  //           ? moment(available_to).format("YYYY-MM-DD")
  //           : null,
  //     },
  //     config
  //   );

  //   console.log(response);

  //   const json = response.data as AlbumJson;

  //   return new Album(
  //     json.target_id,
  //     json.timestamp,
  //     json.publisher_id,
  //     json.publisher_name,
  //     json.publisher_username,
  //     json.publisher_selfie_image_url,
  //     MediaResource.fromJson(json.media_url),
  //     json.comment,
  //     new Date(response.data.created_at),
  //     new Date(response.data.updated_at),
  //     response.data.deleted_at ? new Date(response.data.deleted_at) : null
  //   );
  // }

  static async delete(temple_id: string, timestamp: number): Promise<Album> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/albums/temple_${temple_id}_${timestamp}`,
      config
    );

    console.log(response);

    const json = response.data.data as AlbumJson;

    const media = MediaResource.fromJson(json.media_url);

    return new Album(
      json.target_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      media,
      json.comment,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }
}
