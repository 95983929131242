import { createApp } from "vue";
import router from "@/router";
import store from "@/store";
import App from "@/App.vue";
import "@/assets/global.scss";

import FontAwesomeIcon from "@/plugins/font-awesome";
import "@/plugins/amplify";
import "@/plugins/yup";

import '@vuepic/vue-datepicker/dist/main.css';

createApp(App)
  .use(router)
  .use(store)
  .component("fa", FontAwesomeIcon)
  .mount("#app");
