
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: String,
    placeholder: String,
    name: String,
    id: String,
    type: String,
    autocomplete: String,
    modelValue: String,
    error_message: String,
    step: Number,
  },

  methods: {
    onValueChanged(e: Event) {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }
      this.$emit("update:modelValue", e.target.value);
      this.$emit("change");
    },
  },
});
